<template>
  <div class="MyCertificate">
    <div class="container">
      <ul class="p-0">
        <li v-for="(item, index) in list" :key="index">
          <iq-card class="iq-card p-2">
            <div class="row">
              <div class="col-sm-3 col-3">
                <img
                  v-if="item.image"
                  style="height: 150px"
                  class="w-100"
                  :src="item.image"
                  alt=""
                />

                <span v-else>123</span>
              </div>
              <div class="col-sm-9 col-9">
                <div class="row h-100">
                  <div
                    class="
                      col-sm-6
                      d-flex
                      flex-column
                      justify-content-between
                      py-2
                    "
                  >
                    <div>
                      <div class="title">{{ item.title }}</div>
                      <div>{{ item.introduce }}</div>
                    </div>
                  </div>
                  <div
                    class="
                      col-sm-6
                      col-6
                      d-flex
                      align-items-end
                      justify-content-end
                      pr-3
                      pb-2
                      btns
                    "
                  >
                    <!-- v-if="item.certificate == 1" -->
                    <div
                      class="btnStyle blue"
                      @click="
                        handleClickPopup(item.digital_certificate_template)
                      "
                    >
                      Digital certificate
                    </div>
                    <div
                      class="btnStyle"
                      v-if="item.blockchain_certificate == 1"
                      @click="
                        handleClickPopup(item.blockchain_certificate_template)
                      "
                    >
                      Blockchain certificate
                    </div>
                    <div
                      class="btnStyle"
                      v-if="
                        item.blockchain_certificate != 1 &&
                          item.blockchain_certificate_template
                      "
                      @click="programPayPackage(item.program_id)"
                    >
                      Pay
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </iq-card>
        </li>
      </ul>
    </div>
    <div class="popup" v-if="popupIsShow">
      <div class="imgflex" v-loading="imgLoading">
        <img class="img" :src="src" alt="" />
        <img
          src="../../../assets/images/public/close.png"
          @click="popupIsShow = false"
          alt=""
          class="iconImg"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MyCertificate",
  mounted() {},
  components: {},
  props: {
    list: {
      type: Array
    }
  },
  data() {
    return {
      popupIsShow: false,
      myCertificate: [],
      src: "",
      imgLoading: false
    };
  },
  methods: {
    // jumpPay (id) {
    //   this.courseCertificatePayPackage(
    //     id,
    //     Intl.DateTimeFormat().resolvedOptions().timeZone
    //   );
    // },
    programPayPackage(id, time) {
      this.$http
        .programPayPackage({
          program_id: id,
          type: 2,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        .then(res => {
          // if (res.status == 200) {
          //   // console.log(res.data);
          //   if (res.data.is_jump == 1) {
          //     this.$router.push({
          //       path: `/PayMoney`,
          //       query: {
          //         model_type: 6,
          //         id: res.data.id,
          //       },
          //     });
          //   } else if (res.data.is_jump == 0) {
          //     this.$router.push({
          //       path: `/PaySuccess`,
          //       query: {
          //         typeStatus: 1,
          //       },
          //     });
          //   }
          // }
        });
    },
    handleClickPopup(licence) {
      this.imgLoading = true;
      this.src = licence;
      this.popupIsShow = true;
      this.imgLoading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.MyCertificate {
  min-height: 100vh;
  .certificate {
    height: 80px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #50b5ff;
    line-height: 24px;
  }
  .language {
    color: #666666;
    line-height: 24px;
  }
  .time {
    color: #666666;
    line-height: 24px;
  }
  .btns {
    .btnStyle {
      width: 168px;
      height: 45px;
      background: #fffcf3;
      border: 1px solid #ffba68;
      border-radius: 5px;
      color: #ffba68;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .blue {
      background: #f5fbff;
      border: 1px solid #50b5ff;
      color: #50b5ff;
      margin-right: 15px;
    }
  }
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    outline: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1024;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgflex {
      position: relative;
      .img {
        width: 560px;
      }
      .iconImg {
        position: absolute;
        top: -17px;
        right: -17px;
      }
    }
  }
}
</style>
