<template>
  <div class="Course">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" :moduleName="$route.meta.name">
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section style="font-size: 20px" class="container pt-3 pb-3">
            <i class="ri-award-line"></i>
            Program Certificate
          </section>
        </iq-card>
      </template>
    </FullBanner>

    <tab-content>
      <tab-content-item
        :active="active == 'prerecording'"
        id="prerecording"
        aria-labelled-by="prerecording-tab-two"
      >
        <MyCertificateItem v-if="myCertificate.length" :list="myCertificate" />
        <div
          v-else
          class="d-flex justify-content-center"
          style="background:#fff; padding:60px 0;"
        >
          <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" />
        </div>
      </tab-content-item>
    </tab-content>
    <div
      class="d-flex justify-content-end container"
      v-if="myCertificate.length"
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="12"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import FullBanner from "@/components/ComComponents/FullBanner";
import MyCertificateItem from "./Components/Certificate";

export default {
  name: "Course",
  components: { FullBanner, MyCertificateItem },
  data () {
    return {
      active: "prerecording",
      myCertificate: [],
      total: 0,
      page: 1,
      size: 12,
    };
  },
  mounted () {
    this.getAwardCertificate();
  },
  methods: {
    handleCurrentChange (page) {
      this.page = page;
      this.getAwardCertificate();
    },
    getAwardCertificate () {
      this.$http.getProgramCertificate({
        page: this.page,
        per_page: 12,
      }).then((res) => {
        if (res.status == 200) {
          this.myCertificate = res.data.data;
          // console.log('award-certificate', res.data);
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Course {
  min-height: 100vh;
  .headerContent {
    .content {
      padding-top: 28px;
      .searchBox {
        padding: 0 8px;
        position: relative;
        img {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
      .selectBox {
        margin: 14px 0 50px;
      }
    }
  }
}

.tab-btn-my {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
